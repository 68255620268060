import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import QRCode from "react-qr-code";
import React, { useCallback, useEffect, useState } from "react";
import { ValidationModel } from "../../../../components/Model/ValidationModel";
import {
  handleFieldData,
  resetStepData,
  resetToggle,
} from "../../../../reducers/inspectionReducer";
import {
  createModulePackaging,
  getContractorData,
  getContractorLocationData,
  getOEMModelData,
} from "../../../../service/apiService";
import { useAppDispatch, useAppSelector } from "../../../../store/rtctype";
import SerialNumberGeneration from "../../fieldComponants/CustomAction/SerialNumberGeneration";
import FileField from "../../fieldComponants/FileField";
import FileReadField from "../../fieldComponants/FileReadField";
import InputField from "../../fieldComponants/InputField";
import { NavStep } from "../../fieldComponants/NavStep";
import { ScanField } from "../../fieldComponants/ScanField";
import SelectField from "../../fieldComponants/SelectField";
import { fieldmap, stepSop } from "../../fieldmap";
import {
  createModulePackagingService,
  successToast,
} from "../../serviceCallback";
import { Field, Step } from "../../types";
import SopHeader from "../sopHeader";
import ArrowCircleDownIcon from "@mui/icons-material/ArrowCircleDown";
import { Backdrop, Box, Button, Checkbox, Fade, Modal } from "@mui/material";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";
import SubmitField from "../../fieldComponants/Submit";
import { toast } from "react-toastify";

type Worksheet = XLSX.WorkSheet;
type Range = XLSX.Range;

const styleGreen = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 350,
  maxHeight: "85%",
  background: "white",
  borderRadius: "10px",
  boxShadow: "0px 3px 15px rgba(0, 0, 0, 0.2)",
  outline: "none",
  overflow: "hidden",
  overflowY: "scroll",
  scrollbarWidth: "none",
  scrollbarColor: "transparent transparent",
  p: 4,
};

export default function ModulePackagingForm({ step }: { step: string }) {
  const formData = useAppSelector((state: any) => state.inspection).formData;
  const userData = useAppSelector((state) => state.user).user;
  const [currentActive, setCurrentActive] = useState<number>(0);
  const [stepFieldMap, setStepFieldMap] = useState<Step>(fieldmap[step]);
  const [open, setOpen] = useState<boolean>(false);
  const [eropen, setErOpen] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [loadingP, setLoadingP] = useState<boolean>(false);
  const [paramError, setParamError] = useState<string[]>([]);
  const [packsData, setPacksData] = useState<any>([]);
  const [isTable, setIsTable] = React.useState<boolean>(false);
  const dispatch = useAppDispatch();
  const [oemData, setOEMData] = useState<any>();
  const [contractorData, setContractorData] = useState<any>();
  const [contractorLocationData, setContractorLocationData] = useState<any>();

  const handleCreateData = (e: any) => {
    const data = packsData?.filter((item: any) => item?.confirm);
    if (data?.length <= 0) {
      toast.error("Please select atleast one packId");
      return;
    }
    const body: any = { data: data?.map((obj: any) => obj?._id) };

    // if (e.type === "reject") {
    //   body.type = "reject";
    // }
    const authToken = localStorage.getItem("accessToken");
    setLoadingP(true);
    createModulePackaging(body, authToken as string)
      .then((res: any) => {
        if (res?.success) {
          successToast(res?.data?.message);
          setLoadingP(false);
          setPacksData([]);
          const currentDataObj = { ...formData[step] };
          const objectKeys = Object.keys(currentDataObj);
          for (let i = 0; i < objectKeys.length; i++) {
            currentDataObj[objectKeys[i]] = "";
          }
          dispatch(resetStepData({ step: step, value: currentDataObj }));
          dispatch(resetToggle(!formData.toggle));
          // setLoading(false);
          // setPacksData(res?.data?.data);
          // setIsTable(true);
          setOpen(false);
        } else {
          setLoadingP(false);
          successToast(res?.message?.message);
          setPacksData([]);
          const currentDataObj = { ...formData[step] };
          const objectKeys = Object.keys(currentDataObj);
          for (let i = 0; i < objectKeys.length; i++) {
            currentDataObj[objectKeys[i]] = "";
          }
          dispatch(resetStepData({ step: step, value: currentDataObj }));
          dispatch(resetToggle(!formData.toggle));
        }
      })
      .catch((e: any) => {
        setLoadingP(false);
      });
  };
  const handleRejectData = (e: any) => {
    const data = packsData?.filter((item: any) => !item?.confirm);
    // if (data?.length <= 0) {
    //   toast.error("Please select atleast one packId");
    //   return;
    // }
    const body: any = { data: data?.map((obj: any) => obj?._id) };

    if (e.type === "reject") {
      body.type = "reject";
    }
    const authToken = localStorage.getItem("accessToken");
    setLoadingP(true);
    createModulePackaging(body, authToken as string)
      .then((res: any) => {
        if (res?.success) {
          successToast("Rejected successfully");
          setLoadingP(false);
          setPacksData([]);
          const currentDataObj = { ...formData[step] };
          const objectKeys = Object.keys(currentDataObj);
          for (let i = 0; i < objectKeys.length; i++) {
            currentDataObj[objectKeys[i]] = "";
          }
          dispatch(resetStepData({ step: step, value: currentDataObj }));
          dispatch(resetToggle(!formData.toggle));
          // setLoading(false);
          // setPacksData(res?.data?.data);
          // setIsTable(true);
          setOpen(false);
        } else {
          setLoadingP(false);
          successToast(res?.message?.message);
          setPacksData([]);
          const currentDataObj = { ...formData[step] };
          const objectKeys = Object.keys(currentDataObj);
          for (let i = 0; i < objectKeys.length; i++) {
            currentDataObj[objectKeys[i]] = "";
          }
          dispatch(resetStepData({ step: step, value: currentDataObj }));
          dispatch(resetToggle(!formData.toggle));
        }
      })
      .catch((e: any) => {
        setLoadingP(false);
      });
  };

  useEffect(() => {
    const authToken = localStorage.getItem("accessToken") as string;
    getOEMModelData(authToken).then((res) => {
      if (res.success && stepFieldMap) {
        setOEMData(res.data);
        const { oem_models, oems } = res.data;
        const new_fields = { ...stepFieldMap };
        const oem_option = stepFieldMap["fields"].findIndex(
          (field: Field) => field.key === "oem"
        );
        const oem_model_option = stepFieldMap["fields"].findIndex(
          (field: Field) => field.key === "oem_model"
        );

        new_fields.fields[oem_option]["option"] = oems;
        new_fields.fields[oem_model_option]["option"] = oem_models;
        setStepFieldMap(new_fields);
      }
    });

    getContractorData(authToken, { pagination: false }).then((res: any) => {
      if (res.success && stepFieldMap) {
        console.log("%c Line:96 🥃 res", "color:#42b983", res);
        setContractorData(res.data?.data?.docs);
        const new_fields = { ...stepFieldMap };

        const arr: any = [];
        res?.data?.data?.docs?.map((obj: any) => {
          arr.push(obj?.name);
        });
        const assembly_contractor = stepFieldMap["fields"].findIndex(
          (field: Field) => field.key === "assembly_contractor"
        );

        new_fields.fields[assembly_contractor]["option"] = arr;
        setStepFieldMap(new_fields);
      }
    });
    getContractorLocationData(authToken, { pagination: false }).then(
      (res: any) => {
        if (res.success && stepFieldMap) {
          console.log("%c Line:96 🥃 res", "color:#42b983", res);
          setContractorLocationData(res.data?.data?.docs);
          const new_fields = { ...stepFieldMap };

          const arr: any = [];
          res?.data?.data?.docs?.map((obj: any) => {
            arr.push(obj?.identifier);
          });
          const assembly_location = stepFieldMap["fields"].findIndex(
            (field: Field) => field.key === "assembly_location"
          );

          new_fields.fields[assembly_location]["option"] = arr;
          setStepFieldMap(new_fields);
        }
      }
    );
  }, []);

  useEffect(() => {
    const currentFormData = { ...formData[step] };
    const updatedFields: any = stepFieldMap;
    if (currentFormData?.assembly_contractor) {
      const assembly_location = stepFieldMap["fields"].findIndex(
        (field: Field) => field.key === "assembly_location"
      );

      const id: any = contractorData?.filter(
        (obj: any) => obj?.name === formData[step]["assembly_contractor"]
      );
      const arr: any = [];
      contractorLocationData
        ?.filter((obj: any) => obj?.contractor === id[0]?._id)
        ?.map((res: any) => arr.push(res?.identifier));
      updatedFields.fields[assembly_location].option = arr;

      if (
        contractorLocationData?.filter(
          (obj: any) => obj?.contractor === id[0]?._id
        )?.length > 0
      ) {
        dispatch(
          handleFieldData({
            step: step,
            value: contractorLocationData?.filter(
              (obj: any) => obj?.contractor === id[0]?._id
            )[0]?.identifier,
            key: "assembly_location",
          })
        );
      }
      setStepFieldMap({ ...updatedFields });
    }
  }, [formData[step]?.assembly_contractor]);
  useEffect(() => {
    const currentFormData = { ...formData[step] };
    const updatedFields: any = stepFieldMap;
    if (currentFormData?.oem && oemData) {
      const { oem_models, oems } = oemData;

      const oem_model_option = stepFieldMap["fields"].findIndex(
        (field: Field) => field.key === "oem_model"
      );
      updatedFields.fields[oem_model_option].option = oem_models?.filter(
        (obj: any) => obj?.oem === formData[step]["oem"]
      );
      if (
        oem_models?.filter((obj: any) => obj?.oem === formData[step]["oem"])
          ?.length > 0
      ) {
        dispatch(
          handleFieldData({
            step: step,
            value: oem_models?.filter(
              (obj: any) => obj?.oem === formData[step]["oem"]
            )[0]?.code,
            key: "oem_model",
          })
        );
      }
      setStepFieldMap({ ...updatedFields });
    }
  }, [formData[step]?.oem]);

  useEffect(() => {
    const currentFormData = { ...formData[step] };

    // const updatedFields: any = stepFieldMap

    // if (currentFormData?.assemmbly_contractor === "Alchemy") {
    //   updatedFields.fields[7].option = ["HTX"]
    //   setStepFieldMap(updatedFields)
    // } else {
    //   updatedFields.fields[7].option = ["DTX"]
    //   setStepFieldMap(updatedFields)
    // }
    // console.log("%c Line:89 🍑 updatedFields", "color:#42b983", updatedFields)
    const ObjKey = Object.keys(currentFormData);
    let constCount = 0;
    for (let i = 0; i < ObjKey.length; i++) {
      if (currentFormData[ObjKey[i]]) {
        constCount++;
      }
    }
    setCurrentActive(constCount);
  }, [formData[step], formData[step]?.assemmbly_contractor]);

  // useEffect(() => {
  //   if (formData[step]?.assemmbly_contractor) {
  //     const currentFormData = { ...formData[step] };

  //     const updatedFields: any = stepFieldMap;

  //     if (currentFormData?.assemmbly_contractor === "Alchemy") {
  //       updatedFields.fields[7].option = ["HTX"];
  //       currentFormData.assembly_location = "HTX";
  //       dispatch(
  //         handleFieldData({
  //           step: "step_12",
  //           value: "HTX",
  //           key: "assembly_location",
  //         })
  //       );
  //       setStepFieldMap(updatedFields);
  //     } else {
  //       currentFormData.assembly_location = "DTX";
  //       dispatch(
  //         handleFieldData({
  //           step: "step_12",
  //           value: "DTX",
  //           key: "assembly_location",
  //         })
  //       );
  //       updatedFields.fields[7].option = ["DTX"];
  //       setStepFieldMap(updatedFields);
  //     }
  //   }
  // }, [formData, formData[step]?.assemmbly_contractor]);

  const handleSubmit = () => {
    setLoading(true);
    createModulePackagingService(formData[step], userData.id)?.then(
      (res: any) => {
        if (!res.success) {
          setLoading(false);
          setParamError(errorHandle(res.message));
          setErOpen(true);
        } else {
          if (res?.data?.data?.length === 0) {
            setLoading(false);
            setParamError(
              errorHandle({ message: "No data found for this condition" })
            );
            setErOpen(true);
          }
          const currentDataObj = { ...formData[step] };
          const objectKeys = Object.keys(currentDataObj);
          for (let i = 0; i < objectKeys?.length; i++) {
            currentDataObj[objectKeys[i]] = "";
          }
          // dispatch(resetStepData({ step: step, value: currentDataObj }));
          // dispatch(resetToggle(!formData.toggle));
          const data =
            res?.data?.data && res?.data?.data?.length > 0
              ? res?.data?.data?.map((obj: any) => ({ ...obj, confirm: false }))
              : [];
          setLoading(false);
          setPacksData(data);
          setIsTable(true);
        }
      }
    );
  };

  const errorHandle = (errors: any) => {
    const new_err = [];
    // for (const error in errors) {
    //   const stepField = fieldmap[step].fields;
    //   const stepFieldIndex = stepField.findIndex(
    //     (field: any) => field.key == error
    //   );

    //   if (stepFieldIndex >= 0) {
    //     new_err.push(
    //       `${stepField[stepFieldIndex].name} : ${JSON.stringify(errors)}`
    //     );
    //   } else {
    //     new_err.push(`Error : ${JSON.stringify(errors)}`);
    //   }
    // }
    new_err.push(errors?.message);
    setParamError([errors?.message]);
    return new_err;
  };

  const handleErrorClose = useCallback(() => {
    setErOpen(false);
    setParamError([]);
  }, [paramError, setParamError]);

  const exportToExcel = async () => {
    const currentDate = new Date();
    const formattedDate = currentDate.toLocaleDateString("en-GB");
    const fileName = `module_packaging-${formattedDate}.xlsx`;

    const worksheet = XLSX.utils.aoa_to_sheet([
      [
        "Pack ID",
        "Product ID",
        "Pack AH",
        "Customer Name",
        "Field Segment",
        "Samsar Module ID",
        "Bin ID",
        "AH",
        "SoH %",
        "ACIR",
        "OEM Serial Number",
        "QR Code Link",
        "QR to",
      ],
    ]);

    let currentRow = 2;
    for (let i = 0; i < packsData?.length; i++) {
      const { packId, product_id, pack, ah, customer, fieldSegment } =
        packsData[i];
      const rowsToMerge = pack.length;
      const qrLink = `https://www.nthlifebattery.com/#/passport/${packId}`;

      XLSX.utils.sheet_add_aoa(
        worksheet,
        [[packId, product_id, ah?.toFixed(2), customer, fieldSegment]],
        {
          origin: "A" + currentRow,
        }
      );
      worksheet["A" + currentRow].l = { Target: qrLink };
      const moduleData = pack?.map(
        ({
          battery_module,
          binName,
          ah,
          soh,
          acir,
          oem_serial_id,
        }: {
          battery_module: string;
          binName: string;
          ah: number;
          soh: number;
          acir: number;
          oem_serial_id: string;
        }) => [
          battery_module,
          binName,
          ah?.toFixed(2),
          soh?.toFixed(2),
          acir,
          oem_serial_id ? oem_serial_id : " - ",
        ]
      );
      XLSX.utils.sheet_add_aoa(worksheet, moduleData, {
        origin: "F" + currentRow,
      });

      XLSX.utils.sheet_add_aoa(worksheet, [[qrLink]], {
        origin: "L" + currentRow,
      });
      worksheet["L" + currentRow].l = { Target: qrLink };
      const qrCodeFormula = `IMAGE("https://chart.googleapis.com/chart?chs=100x100&cht=qr&chl=${qrLink}")`;
      worksheet[`M${currentRow}`] = { f: qrCodeFormula }; // QR to

      mergeCells(worksheet, currentRow, 1, currentRow + rowsToMerge - 1, 1);
      mergeCells(worksheet, currentRow, 2, currentRow + rowsToMerge - 1, 2);
      mergeCells(worksheet, currentRow, 3, currentRow + rowsToMerge - 1, 3);
      mergeCells(worksheet, currentRow, 4, currentRow + rowsToMerge - 1, 4);
      mergeCells(worksheet, currentRow, 5, currentRow + rowsToMerge - 1, 5);
      mergeCells(worksheet, currentRow, 12, currentRow + rowsToMerge - 1, 12);
      mergeCells(worksheet, currentRow, 13, currentRow + rowsToMerge - 1, 13);

      currentRow += rowsToMerge;
    }
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Data");
    const excelBuffer = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });
    const fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const file = new Blob([excelBuffer], { type: fileType });
    saveAs(file, fileName);
  };
  const exportToExcelOne = async () => {
    const currentDate = new Date();
    const formattedDate = currentDate.toLocaleDateString("en-GB");
    const fileName = `module_packaging-${formattedDate}.xlsx`;

    const worksheet = XLSX.utils.aoa_to_sheet([
      ["Pack ID", "Product ID", "QR Code Link"],
    ]);

    let currentRow = 2;
    for (let i = 0; i < packsData?.length; i++) {
      const { packId, product_id } = packsData[i];
      const qrLink = `https://www.nthlifebattery.com/#/passport/${packId}`;
      // const rowsToMerge = 3;

      XLSX.utils.sheet_add_aoa(worksheet, [[packId, product_id, qrLink]], {
        origin: "A" + currentRow,
      });

      worksheet["C" + currentRow].l = { Target: qrLink };
      // mergeCells(worksheet, currentRow, 1, currentRow + rowsToMerge - 1, 1);
      // mergeCells(worksheet, currentRow, 2, currentRow + rowsToMerge - 1, 2);
      // mergeCells(worksheet, currentRow, 3, currentRow + rowsToMerge - 1, 3);

      // currentRow += rowsToMerge;
      currentRow += 1;
    }

    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Data");
    const excelBuffer = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });
    const fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const file = new Blob([excelBuffer], { type: fileType });
    saveAs(file, fileName);
  };

  const mergeCells = (
    worksheet: Worksheet,
    startRow: number,
    startCol: number,
    endRow: number,
    endCol: number
  ): void => {
    const mergeCell: Range = {
      s: { r: startRow - 1, c: startCol - 1 },
      e: { r: endRow - 1, c: endCol - 1 },
    };
    if (!worksheet["!merges"]) worksheet["!merges"] = [];
    worksheet["!merges"].push(mergeCell);
  };

  const handleChange = (id: any, e: any) => {
    setPacksData((packsData: []) =>
      packsData?.map((user: any) =>
        user?._id === id ? { ...user, confirm: e?.target?.checked } : user
      )
    );
  };

  return (
    <React.Fragment>
      {/* {loading && <Spiner />} */}
      <div className="sm-stepForm-inner">
        <div className="sm-stepForm-banner">
          <SopHeader title={stepFieldMap?.title} link={stepSop[step]} />
        </div>
        <div className="sm-stepForm-wrap">
          <div className="sm-colm-12">
            <div className="sm-commen-box">
              {stepFieldMap?.fields?.map((field: Field, index: number) => {
                if (field.type === "scan") {
                  return (
                    <ScanField
                      status={index <= currentActive}
                      data={field}
                      step={step}
                      value={formData[step][field.key]}
                      key={field.key}
                    />
                  );
                } else if (field.type === "submit") {
                  return (
                    <SubmitField
                      status={index <= currentActive}
                      data={field}
                      step={step}
                      loading={loading}
                      value={formData[step][field.key]}
                      key={field.key}
                      onClick={handleSubmit}
                    />
                  );
                } else if (field.type === "field") {
                  return (
                    <InputField
                      status={index <= currentActive}
                      data={field}
                      step={step}
                      value={formData[step][field.key]}
                      key={field.key}
                    />
                  );
                } else if (field.type === "select") {
                  return (
                    <SelectField
                      status={index <= currentActive}
                      data={field}
                      step={step}
                      value={
                        formData[step][field.key]
                          ? formData[step][field.key]
                          : ""
                      }
                      key={field.key}
                    />
                  );
                } else if (field.type === "file") {
                  return (
                    <FileField
                      status={index <= currentActive}
                      data={field}
                      step={step}
                      value={formData[step][field.key]}
                      key={field.key}
                    />
                  );
                } else if (field.type === "file_read") {
                  return (
                    <FileReadField
                      status={index <= currentActive}
                      data={field}
                      step={step}
                      value={formData[step][field.key]}
                      key={field.key}
                    />
                  );
                }
                // else if (field.type === "status") {
                //   return (
                //     <StatusField
                //       status={index <= currentActive}
                //       data={field}
                //       step={step}
                //       loading={loading}
                //       value={formData[step][field.key]}
                //       key={field.key}
                //       onClick={handleSubmit}
                //     />
                //   );
                // }
                else if (field.type === "custom") {
                  return (
                    <SerialNumberGeneration
                      status={index <= currentActive}
                      data={field}
                      key={field.key}
                      onClick={handleSubmit}
                    />
                  );
                } else if (field.type === "reset") {
                  return (
                    <NavStep
                      step={step}
                      data={field}
                      loading={loading}
                      status={index <= currentActive}
                      key={field.key}
                      onClick={handleSubmit}
                    />
                  );
                }
                return null;
              })}

              {/* {isTable && ( */}
              {isTable && packsData && packsData?.length > 0 && (
                <div className="sm-table-wrapper" id="printablediv">
                  <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 650 }} aria-label="spanning table">
                      <TableHead>
                        <TableRow>
                          <TableCell></TableCell>
                          <TableCell>Pack ID</TableCell>
                          <TableCell>Pack QR</TableCell>
                          <TableCell>Product ID</TableCell>
                          <TableCell>Pack AH</TableCell>
                          <TableCell>ACIR Difference</TableCell>
                          <TableCell>Customer</TableCell>
                          <TableCell>Field Segment</TableCell>
                          <TableCell>Samsar Module ID</TableCell>
                          <TableCell>Bin ID</TableCell>
                          <TableCell>AH</TableCell>
                          <TableCell>SoH %</TableCell>
                          <TableCell>ACIR</TableCell>
                          <TableCell
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "space-between",
                              minHeight: 82,
                            }}
                          >
                            {/* <Box
                             
                            > */}
                            OEM Serial Number{" "}
                            <div style={{ display: "flex", gap: "15px" }}>
                              <div>
                                <p style={{ marginTop: "24px" }}>Printlist</p>{" "}
                                <ArrowCircleDownIcon
                                  onClick={exportToExcelOne}
                                  sx={{ cursor: "pointer" }}
                                />
                              </div>
                              <div>
                                <p style={{ marginTop: "24px" }}>Checklist</p>
                                <ArrowCircleDownIcon
                                  onClick={exportToExcel}
                                  sx={{ cursor: "pointer" }}
                                />
                              </div>
                            </div>
                            {/* </Box> */}
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {packsData?.map((pack: any, rowIndex: number) => (
                          <React.Fragment key={rowIndex}>
                            <TableRow>
                              <TableCell rowSpan={pack.pack.length + 1}>
                                <Checkbox
                                  checked={pack?.confirm}
                                  onChange={(e) => handleChange(pack?._id, e)}
                                />
                              </TableCell>
                              <TableCell rowSpan={pack.pack.length + 1}>
                                <a
                                  href={`https://www.nthlifebattery.com/#/passport/${pack.packId}`}
                                  target="_blank"
                                >
                                  {pack.packId}
                                </a>
                              </TableCell>
                              <TableCell rowSpan={pack.pack.length + 1}>
                                <QRCode
                                  value={`https://www.nthlifebattery.com/#/passport/${pack.packId}`}
                                  size={128}
                                  level="M"
                                />
                              </TableCell>
                              <TableCell rowSpan={pack.pack.length + 1}>
                                {pack.product_id}
                              </TableCell>
                              <TableCell rowSpan={pack.pack.length + 1}>
                                {pack?.ah?.toFixed(2)}
                              </TableCell>
                              <TableCell rowSpan={pack.pack.length + 1}>
                                {pack?.acirDiff?.toFixed(2)}
                              </TableCell>
                              <TableCell rowSpan={pack.pack.length + 1}>
                                {pack?.customer}
                              </TableCell>
                              <TableCell rowSpan={pack.pack.length + 1}>
                                {pack?.fieldSegment}
                              </TableCell>
                            </TableRow>
                            {pack.pack.map((module: any, index: number) => (
                              <TableRow key={rowIndex}>
                                <TableCell>{module.battery_module}</TableCell>
                                <TableCell>{module.binName}</TableCell>
                                <TableCell>{module?.ah?.toFixed(2)}</TableCell>
                                <TableCell>{module?.soh?.toFixed(2)}</TableCell>
                                <TableCell>{module?.acir}</TableCell>
                                <TableCell>
                                  {module.oem_serial_id || " - "}
                                </TableCell>
                              </TableRow>
                            ))}
                          </React.Fragment>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  <div
                    style={{
                      display: "flex",
                      margin: 10,
                      justifyContent: "center",
                      alignItems: "center",
                      maxWidth: "50%",
                      gap: "10px",
                      marginInline: "auto",
                    }}
                  >
                    {loadingP ? (
                      <Button
                        style={{ minWidth: "100%" }}
                        disabled={loadingP}
                        variant="contained"
                      >
                        Please Wait...
                      </Button>
                    ) : (
                      <>
                        {/* <Button
                          style={{ minWidth: "200px" }}
                          disabled={loadingP}
                          onClick={() => {
                            handleCreateData({ type: "reject" });
                          }}
                          variant="contained"
                        >
                          Reject
                        </Button> */}
                        <Button
                          variant="contained"
                          style={{ minWidth: "200px", marginInline: "auto" }}
                          disabled={loadingP}
                          onClick={() => {
                            setOpen(!open);
                            // handleCreateData({ type: "confirm" });
                          }}
                        >
                          Confirm
                        </Button>
                      </>
                    )}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      <ValidationModel
        open={eropen}
        setOpen={handleErrorClose}
        validation={paramError}
      />

      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={() => setOpen(false)}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={open}>
          <Box sx={styleGreen}>
            {/* <div
              style={{
                margin: "10px",
                textAlign: "center",
                color: "black",
                fontSize: "20px",
              }}
            >
              <h2>Confirmed and Unconfirmed Data</h2>
            </div> */}

            <div
              style={{
                marginBottom: "20px",
                padding: "10px",
                backgroundColor: "#e0f7fa",
                borderRadius: "5px",
              }}
            >
              <h3>Confirmed Pack</h3>
              <ul>
                {packsData
                  ?.filter((item: any) => item?.confirm)
                  ?.map((item: any) => (
                    <li key={item?._id}>{item?.packId}</li>
                  ))}
              </ul>
            </div>

            <div
              style={{
                marginBottom: "20px",
                padding: "10px",
                backgroundColor: "#ffecb3",
                borderRadius: "5px",
              }}
            >
              <h3>Rejected Pack</h3>
              <ul>
                {packsData
                  ?.filter((item: any) => !item?.confirm)
                  ?.map((item: any) => (
                    <li key={item?._id}>{item?.packId}</li>
                  ))}
              </ul>
            </div>

            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                gap: "10px",
              }}
            >
              <Button
                variant="outlined"
                onClick={() => setOpen(false)}
                // style={{ marginTop: "10px" }}
              >
                Close
              </Button>
              <Button
                disabled={loadingP}
                variant="contained"
                onClick={() => {
                  const cData = packsData?.filter((item: any) => item?.confirm);
                  const rData = packsData?.filter(
                    (item: any) => !item?.confirm
                  );

                  if (cData?.length > 0) {
                    handleCreateData({ type: "confirm" });
                  }
                  if (rData?.length > 0) {
                    handleRejectData({ type: "reject" });
                  }
                }}
              >
                Confirm
              </Button>
            </div>
          </Box>
        </Fade>
      </Modal>
    </React.Fragment>
  );
}
