import axios from "axios";

// const BASE_URL = "http://localhost:8000";
// const BASE_URL = "http://samsar.eastus2.cloudapp.azure.com:8000";
// const BASE_URL = "https://samsar.eastus2.cloudapp.azure.com:8001";
// const BASE_URL = "https://samsar.eastus2.cloudapp.azure.com:8000";
const BASE_URL = "https://dynamic-openly-heron.ngrok-free.app";
// const BASE_URL = "https://9249-20-10-144-52.ngrok-free.app";
// const SERVER_URL = "https://uat-api.samsar.tech" //for testing
// const SERVER_URL = "https://qnk3c0s0-8080.inc1.devtunnels.ms"  //for Local
const SERVER_URL = "https://samsar-test-302668586270.us-central1.run.app"  //for Local
// const SERVER_URL = "https://api.samsar.tech"  //for production
// const SERVER_URL = "https://solar-backend-nodejs-q76vrtgcha-uc.a.run.app"
const FILE_URL = "https://samsar-uat-q76vrtgcha-uc.a.run.app"
export interface APIResponse {
  message: string[];
  status: number;
  data: any;
  success: boolean;
}

export const jsoninstance = axios.create({
  baseURL: BASE_URL,
  timeout: 30000,
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
  },
});
export const jsoninstancenew = axios.create({
  baseURL: SERVER_URL,
  timeout: 3000000,
  headers: {
    "Content-Type": "multipart/form-data",
  },
});
export const jsoninstanceModule = axios.create({
  baseURL: SERVER_URL,
  timeout: 3000000,
  headers: {
    "Content-Type": "application/json",
  },
});

export const jsoninstancefile = axios.create({
  baseURL: FILE_URL,
  timeout: 3000000,
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
  },
});

export const forminstance = axios.create({
  baseURL: BASE_URL,
  timeout: 150000,
  headers: {
    "Content-Type": "multipart/form-data",
  },
});

export const errorHandler = (err: any): APIResponse => {
  const statusCode = err.response?.status;
  const statusresponse = err.response?.data;
  if (statusCode === 401) {
    localStorage.removeItem("accessToken");
    localStorage.removeItem("refreshToken");
    // console.log('window.location.pathname.startsWith("battery-report") : ', window.location);
    if (!(window.location.hash.split("/")[1] === "battery-report" || window.location.hash.split("/")[1] === "passport" || window.location.hash.split("/")[1] === "battery-report-download")) {
      console.log("redirecting=>.....")
      window.location.href = `${window.location.origin}/login`;
    }
  }
  return {
    message: statusresponse,
    status: statusCode,
    data: null,
    success: false,
  };
};

export const responseHandler = (result: any): APIResponse => {
  return {
    message: [],
    data: result.data,
    status: result.status,
    success: true,
  };
};
